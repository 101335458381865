import { Fieldset } from "primereact/fieldset"
import { Controller } from "react-hook-form"
import { InputText } from "primereact/inputtext"
import { FieldError } from "../../../../../../../shared/FieldError"
import { getFieldRulesObjByAccessState } from "../../../getFieldRulesObjByAccessState"
import { getFieldAccessPropObjByAccessState } from "../../../getFieldAccessPropObjByAccessState"

export const Address = ({ formState, formAccessState, i18n }) => {
  const {
    formState: { errors },
    control,
  } = formState

  const { t } = i18n
  return (
    <Fieldset className="fieldSet" legend={t("repeatedWords.address")}>
      <section className="three_columns_grid_base">
        <div>
          <label className="block">{`${t("repeatedWords.street")} *`}</label>
          <Controller
            name="address.street"
            control={control}
            rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
            render={({ field }) => (
              <InputText
                {...getFieldAccessPropObjByAccessState(formAccessState)}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <FieldError fieldErr={errors?.address?.street} />
        </div>

        <div>
          <label className="block">{`${t("repeatedSentences.houseNumber")} *`}</label>
          <Controller
            name="address.houseNumber"
            control={control}
            rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
            render={({ field }) => (
              <InputText
                {...getFieldAccessPropObjByAccessState(formAccessState)}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <FieldError fieldErr={errors?.address?.houseNumber} />
        </div>

        <div>
          <label className="block">{t("repeatedSentences.apartmentNumber")}</label>
          <Controller
            name="address.apartmentNumber"
            control={control}
            rules={getFieldRulesObjByAccessState(formAccessState)}
            render={({ field }) => (
              <InputText
                {...getFieldAccessPropObjByAccessState(formAccessState)}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <FieldError fieldErr={errors?.address?.apartmentNumber} />
        </div>

        <div>
          <label className="block">{`${t("repeatedSentences.postCode")} *`}</label>
          <Controller
            name="address.postCode"
            control={control}
            rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
            render={({ field }) => (
              <InputText
                {...getFieldAccessPropObjByAccessState(formAccessState)}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <FieldError fieldErr={errors?.address?.postCode} />
        </div>

        <div>
          <label className="block">{`${t("repeatedWords.city")} *`}</label>
          <Controller
            name="address.city"
            control={control}
            rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
            render={({ field }) => (
              <InputText
                {...getFieldAccessPropObjByAccessState(formAccessState)}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <FieldError fieldErr={errors?.address?.city} />
        </div>
      </section>
    </Fieldset>
  )
}
