export const getFieldAccessPropObjByAccessState = (formAccessState) => {
    const [formAccessStateValue] = formAccessState
  
    switch (formAccessStateValue?.access) {
      case "readOnly":
        return { readOnly: true }
  
      case "disabled":
        return { disabled: true }
  
      case "normal":
        return {}
  
      default: {
        console.error("Unknown access value.")
        return {}
      }
    }
  }
  