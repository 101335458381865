export const getDefaultValuesObjByYourDataObj = (yourDataObj) => {
  return {
    privatePersonOrCompany: {
      activityType: yourDataObj.typeOfActivity,
      privatePerson: {
        name: yourDataObj.firstName,
        surname: yourDataObj.lastName,
        phoneNumber: yourDataObj.phone,
        email: yourDataObj.email,
      },
      company: {
        name: yourDataObj.company,
        nip: yourDataObj.nip,
      },
    },

    address: {
      street: yourDataObj.street,
      houseNumber: yourDataObj.buldingNumber,
      apartmentNumber: yourDataObj.apartmentNumber,
      postCode: yourDataObj.postCode,
      city: yourDataObj.city,
    },

    bankTransferDetails: {
      name: yourDataObj.bankName,
      accountNumber: yourDataObj.accountNumber,
    },
  }
}
