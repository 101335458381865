import { Link } from "react-router-dom"
import { Menu as PsMenu, MenuItem } from "react-pro-sidebar"
import "./style.css"
import { useLocation } from "react-router-dom"
import { isActive } from "../lib"
import { initialProjectDirectory } from "../../../entities/initialProjectDirectory"

export const Menu = ({ i18n }) => {
  const location = useLocation()
  const currentPath = location.pathname

  const { t } = i18n

  return (
    <PsMenu className="mainMenu">
      <MenuItem
        component={<Link to={`${initialProjectDirectory}/yourAffiliations`} />}
        icon={<i className="pi pi-users icon" />}
        {...isActive.item(currentPath, `${initialProjectDirectory}/yourAffiliations`)}
      >
        {t("menu.yourAffiliationsItem.title")}
      </MenuItem>

      <MenuItem
        component={<Link to={`${initialProjectDirectory}/yourData`} />}
        icon={<i className="pi pi-id-card icon" />}
        {...isActive.item(currentPath, `${initialProjectDirectory}/yourData`)}
      >
        {t("menu.yourDataItem.title")}
      </MenuItem>

      <MenuItem
        component={<Link to={`${initialProjectDirectory}/affiliateLink`} />}
        icon={<i className="pi pi-link icon" />}
        {...isActive.item(currentPath, `${initialProjectDirectory}/affiliateLink`)}
      >
        {t("menu.affiliateLinkItem.title")}
      </MenuItem>

      {/* <MenuItem
        component={<Link to={`${initialProjectDirectory}/statistics`} />}
        icon={<i className="pi pi-chart-bar icon" />}
        {...isActive.item(currentPath, `${initialProjectDirectory}/statistics`)}
      >
        {t("menu.statisticsItem.title")}
      </MenuItem> */}
    </PsMenu>
  )
}
