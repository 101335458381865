import { api } from "../../../../shared/api"

export const getOnSubmitFunc = (componentState, msgsRef, i18n) => async (fData) => {
  const [, setComponentStateValue] = componentState

  setComponentStateValue((prevState) => ({ ...prevState, status: "loading" }))

  const postResponse = await api.affiliate.link.post(fData)

  setComponentStateValue((prevValue) => ({ ...prevValue, status: "waiting" }))

  const { warning, error } = postResponse

  if (warning) {
    msgsRef.current.replace({
      severity: "warn",
      summary: warning.title,
      detail: warning.message,
      sticky: true,
      closable: false,
    })
    return
  }

  if (error) {
    msgsRef.current.replace({
      severity: "error",
      summary: error.title,
      detail: error.message,
      sticky: true,
      closable: false,
    })
    return
  }

  msgsRef.current.replace({
    severity: "success",
    summary: i18n.t("repeatedWords.success"),
    detail: i18n.t("message.successfulOperation.message"),
    sticky: true,
    closable: false,
  })

  document.location.reload()
}
