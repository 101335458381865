export const getInitValues = () => ({
  privatePersonOrCompany: {
    activityType: "",
    privatePerson: {
      name: "",
      surname: "",
      phoneNumber: "",
      email: "",
    },
    company: {
      name: "",
      nip: "",
    },
  },

  address: {
    street: "",
    houseNumber: "",
    apartmentNumber: "",
    postCode: "",
    city: "",
  },

  bankTransferDetails: {
    name: "",
    accountNumber: "",
  },
})
