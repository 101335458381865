import { Fieldset } from "primereact/fieldset"
import { Controller } from "react-hook-form"
import { InputText } from "primereact/inputtext"
import { FieldError } from "../../../../../../../shared/FieldError"
import { getFieldRulesObjByAccessState } from "../../../getFieldRulesObjByAccessState"
import { getFieldAccessPropObjByAccessState } from "../../../getFieldAccessPropObjByAccessState"

export const BankTransferDetails = ({ formState, formAccessState, i18n }) => {
  const {
    formState: { errors },
    control,
  } = formState

  const { t } = i18n
  return (
    <Fieldset className="fieldSet" legend={t("repeatedSentences.bankTransferDetails")}>
      <section className="three_columns_grid_base">
        <div>
          <label className="block">{`${t("repeatedSentences.bankName")} *`}</label>
          <Controller
            name="bankTransferDetails.name"
            control={control}
            rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
            render={({ field }) => (
              <InputText
                {...getFieldAccessPropObjByAccessState(formAccessState)}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <FieldError fieldErr={errors?.bankTransferDetails?.name} />
        </div>

        <div>
          <label className="block">{`${t("repeatedSentences.accountNumber")} *`}</label>
          <Controller
            name="bankTransferDetails.accountNumber"
            control={control}
            rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
            render={({ field }) => (
              <InputText
                {...getFieldAccessPropObjByAccessState(formAccessState)}
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
            )}
          />
          <FieldError fieldErr={errors?.bankTransferDetails?.accountNumber} />
        </div>
      </section>
    </Fieldset>
  )
}
