export const getYourDataObjByFormDataObj = (formDataObj) => {
  return {
    typeOfActivity: formDataObj.privatePersonOrCompany.activityType,
    firstName: formDataObj.privatePersonOrCompany.privatePerson.name,
    lastName: formDataObj.privatePersonOrCompany.privatePerson.surname,
    phone: formDataObj.privatePersonOrCompany.privatePerson.phoneNumber,
    email: formDataObj.privatePersonOrCompany.privatePerson.email,
    company: formDataObj.privatePersonOrCompany.company.name,
    nip: formDataObj.privatePersonOrCompany.company.nip,
    street: formDataObj.address.street,
    buldingNumber: formDataObj.address.houseNumber,
    apartmentNumber: formDataObj.address.apartmentNumber,
    postCode: formDataObj.address.postCode,
    city: formDataObj.address.city,
    bankName: formDataObj.bankTransferDetails.name,
    accountNumber: formDataObj.bankTransferDetails.accountNumber,
  }
}
