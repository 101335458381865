import { WebSiteIcon } from "./svg/WebSiteIcon";
import { PhoneCallIcon } from "./svg/PhoneCallIcon";
import "./style.css";

export const PageFooter = () => (
  <footer className="page_footer">
    <div className="page_footer_link_container">
      <a
        href="tel:+48586904060"
        className="page_footer_link"
        target="_blank"
        rel="noreferrer"
      >
        <PhoneCallIcon className={"icon"} />
        <span>58 690 40 60</span>
      </a>
      <a
        href="https://euro26.pl/portal-afilianta/"
        className="page_footer_link"
        target="_blank"
        rel="noreferrer"
      >
        <WebSiteIcon className={"icon"} />
        <span>https://euro26.pl/portal-afilianta/</span>
      </a>
    </div>
    <span>© {new Date().getFullYear()} All rights reserved.</span>
  </footer>
);
