import { useEffect, useRef, useState } from "react"
import { YourAffiliateLink as UI } from "./ui/YourAffiliateLink"
import { useForm } from "react-hook-form"
import { getFormAccessObjByDefaultValuesObj, initValuesObj } from "./lib"

export const YourAffiliateLink = ({ i18n, defaultValuesObj }) => {
  const isInitialRender = useRef(true)

  const componentState = useState({ status: "waiting" })
  const formState = useForm({ mode: "onChange", defaultValues: defaultValuesObj ? defaultValuesObj : initValuesObj })

  const formAccessState = useState(getFormAccessObjByDefaultValuesObj(defaultValuesObj))
  const [formAccessStateValue] = formAccessState

  const msgsRef = useRef(null)

  const { reset } = formState

  useEffect(() => {
    if (!isInitialRender.current) {
      reset()
    } else isInitialRender.current = false
  }, [formAccessStateValue.access])

  return (
    <UI
      componentState={componentState}
      formState={formState}
      formAccessState={formAccessState}
      msgsRef={msgsRef}
      i18n={i18n}
    />
  )
}
