import "./index.css"
import { lazy, Suspense, useEffect } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import { AbsolutePlatform } from "../shared/AbsolutePlatform"
import { SideBar } from "../features/SideBar"
import { Loading } from "../shared/Loading/ui/Loading"
import { NotFound } from "../pages/NotFound/"
import { useLocation } from "react-router-dom"
import { LogIn as LogInPage } from "../pages/LogIn/index"
import { authTokenName } from "../entities/authTokenName"
import { initialProjectDirectory } from "../entities/initialProjectDirectory"
import { BeUpSoftSign } from "../shared/BeUpSoftSign/"

const YourAffiliationsPage = lazy(() => import("../pages/YourAffiliations"))
const YourDataPage = lazy(() => import("../pages/YourData"))
const AffiliateLinkPage = lazy(() => import("../pages/AffiliateLink"))

export const App = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const authToken = sessionStorage.getItem(authTokenName)

  useEffect(() => {
    if (!authToken && !location.pathname.includes(`${initialProjectDirectory}/logIn`))
      navigate(`${initialProjectDirectory}/logIn`)
  })

  if (authToken) {
    return (
      <AbsolutePlatform maxWidth={1600} secondClassName="global_content_container">
        <SideBar navigate={navigate} />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route exact path={`${initialProjectDirectory}/`} element={<YourAffiliationsPage />}></Route>
            <Route
              exact
              path={`${initialProjectDirectory}/yourAffiliations`}
              element={<YourAffiliationsPage />}
              default
            ></Route>
            <Route exact path={`${initialProjectDirectory}/yourData`} element={<YourDataPage />}></Route>
            <Route exact path={`${initialProjectDirectory}/affiliateLink`} element={<AffiliateLinkPage />}></Route>
            <Route exact path="*" element={<NotFound />}></Route>
          </Routes>
        </Suspense>
      </AbsolutePlatform>
    )
  }

  if (!authToken)
    return (
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route exact path={`${initialProjectDirectory}/logIn`} element={<LogInPage />}></Route>
          <Route exact path="*" element={<NotFound />}></Route>
        </Routes>
        <BeUpSoftSign />
      </Suspense>
    )
}
