import { Sidebar } from "react-pro-sidebar"
import { Menu } from "../../../shared/Menu"
import logo from "../../../shared/lib/img/logo.webp"
import shortLogo from "../../../shared/lib/img/logoMini.webp"
import "./style.css"
import { useTranslation } from "react-i18next"
import { Button } from "primereact/button"
import { Link } from "react-router-dom"
import { initialProjectDirectory } from "../../../entities/initialProjectDirectory"
import { checkDisplay, logOut } from "../../../shared/lib"
import { useMemo } from "react"

export const SideBar = ({ navigate }) => {
  const { i18n } = useTranslation()

  const collapsed = useMemo(
    () => (checkDisplay.width("phone") || checkDisplay.width("tablet") || checkDisplay.width("laptop") ? true : false),
    []
  )

  return (
    <Sidebar className="sideBar" {...{ collapsed }}>
      <div className="side_bar_logo_container">
        <a href="https://euro26.pl/" target="_blank" rel="noreferrer">
          {collapsed ? (
            <img src={shortLogo} className="short_logo" alt="Euro26" />
          ) : (
            <img src={logo} className="logo" alt="Euro26" />
          )}
        </a>
      </div>

      <Menu i18n={i18n} />

      <div className="side_bar_settings_section">
        {/* <Button
          icon="pi pi-cog icon"
          severity="secondary"
          rounded
          text
          onClick={() => navigate(`${initialProjectDirectory}/settings`)}
        /> */}
        {/* <LanguageSwitcher /> */}
        <Button icon="pi pi-sign-out icon" severity="secondary" rounded text onClick={() => logOut(navigate)} />
      </div>
    </Sidebar>
  )
}
