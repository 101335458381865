import { Controller } from "react-hook-form"
import { InputText } from "primereact/inputtext"
import { Button } from "primereact/button"
import { FieldError } from "../../../../shared/FieldError"
import { Messages } from "primereact/messages"
import { getOnSubmitFunc } from "../lib/getOnSubmitFunc"
import {
  getFieldRulesObjByAccessState,
  getFieldAccessPropObjByAccessState,
  getCopyButtonOnClickFunc,
} from "../../../../shared/lib"
import { affiliateLinkCoreString } from "../../../../entities/affiliateLinkCoreString"

export const YourAffiliateLink = ({ componentState, formState, formAccessState, msgsRef, i18n }) => {
  const [componentStateValue] = componentState

  const {
    handleSubmit,
    control,
    formState: { errors, defaultValues },
  } = formState

  const [formAccessStateValue] = formAccessState

  return (
    <form onSubmit={handleSubmit(getOnSubmitFunc(componentState, msgsRef, i18n))}>
      <Messages ref={msgsRef} />
      <section className="three_columns_grid_base align_items_center">
        <div className="text_wrap_nowrap">
          <label>{affiliateLinkCoreString}</label>
          <Controller
            name="code"
            control={control}
            rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
            render={({ field }) => (
              <InputText
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                {...getFieldAccessPropObjByAccessState(formAccessState)}
              />
            )}
          />
          <FieldError fieldErr={errors?.code} />
        </div>

        {formAccessStateValue.access === "normal" ? (
          <div>
            {componentStateValue.status === "loading" ? (
              <Button loading />
            ) : (
              <Button label={i18n.t("repeatedWords.save")} />
            )}
          </div>
        ) : (
          <div>
            <Button
              type="button"
              icon="pi pi-copy"
              tooltip={i18n.t("repeatedWords.copy")}
              outlined
              onClick={getCopyButtonOnClickFunc(affiliateLinkCoreString + defaultValues?.code)}
            />
          </div>
        )}
      </section>
    </form>
  )
}
