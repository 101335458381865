import logo from "./img/beUpSoft.svg"
import "./style.css"

export const BeUpSoftSign = ({ secondClassName = "" }) => (
  <div className={`BeUpSoftSign${secondClassName && " " + secondClassName}`}>
    <span>Projekt i wykonanie</span>
    <a href="https://beupsoft.pl/" target="_blank" rel="noreferrer">
      <img src={logo} alt="BeUpSoftLogo" />
    </a>
  </div>
)
