import { AddressFieldSet, BankTransferDetailsFieldSet, PrivatePersonOrCompanyFieldSet, getEditButtons } from "../lib"
import { useEffect, useRef, useState } from "react"

export const YourData = ({ formState, i18n, dialogState, toastRef, onSubmit, loading }) => {
  const isInitialRender = useRef(true)

  const formAccessState = useState({ access: "disabled" })
  const [formAccessStateValue] = formAccessState

  const { handleSubmit, reset } = formState

  useEffect(() => {
    if (!isInitialRender.current) {
      reset()
    } else isInitialRender.current = false
  }, [formAccessStateValue.access])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PrivatePersonOrCompanyFieldSet formState={formState} formAccessState={formAccessState} i18n={i18n} />
      <AddressFieldSet formState={formState} formAccessState={formAccessState} i18n={i18n} />
      <BankTransferDetailsFieldSet formState={formState} formAccessState={formAccessState} i18n={i18n} />
      {getEditButtons(formAccessState, i18n, dialogState, toastRef, loading)}
    </form>
  )
}
