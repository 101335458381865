import { useMemo, useState } from "react"
import { YourData as UI } from "./ui/YourData"
import { api } from "../../../shared/api"
import { toastMessageConfig } from "../../../entities/toastMessageConfig"
import { getInitValues, getYourDataObjByFormDataObj } from "./lib"
import { useForm } from "react-hook-form"

export const YourData = ({ i18n, defaultValuesObj, dialogState, toastRef }) => {
  const [componentStateValue, setComponentStateValue] = useState({ status: "waiting" })

  const initValues = useMemo(() => getInitValues(), [])
  const formState = useForm({ mode: "onChange", defaultValues: defaultValuesObj ? defaultValuesObj : initValues })


  const getOnSubmit = (i18n, toastRef) => async (fData) => {
    const { t } = i18n

    setComponentStateValue({ status: "loading" })

    const yourDataObj = getYourDataObjByFormDataObj(fData)

    const putYourDataResponse = await api.affiliate.details.put(yourDataObj)

    const { warning, error } = putYourDataResponse

    if (warning) {
      toastRef.current.show({ severity: "warn", summary: warning.title, detail: warning.message })
      setComponentStateValue({ status: "waiting" })
      return
    }

    if (error) {
      toastRef.current.show({ severity: "error", summary: error.title, detail: error.message, sticky: true })
      setComponentStateValue({ status: "waiting" })
      return
    }

    toastRef.current.show({
      severity: "success",
      summary: t("repeatedWords.success"),
      detail: t("message.successfulOperation.message"),
      life: toastMessageConfig.life,
    })
    setComponentStateValue({ status: "waiting" })
    document.location.reload()
  }

  if (componentStateValue.status === "loading")
    return (
      <UI
        formState={formState}
        i18n={i18n}
        dialogState={dialogState}
        toastRef={toastRef}
        onSubmit={getOnSubmit(i18n, toastRef)}
        loading
      />
    )
  if (componentStateValue.status === "waiting")
    return (
      <UI
        formState={formState}
        i18n={i18n}
        dialogState={dialogState}
        toastRef={toastRef}
        onSubmit={getOnSubmit(i18n, toastRef)}
      />
    )
}
