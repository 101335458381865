import i18n from "i18next"
import HttpApi from "i18next-http-backend"
import { initReactI18next } from "react-i18next"
import { initialProjectDirectory } from "./initialProjectDirectory"

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "pl"],
    fallbackLng: "pl",
    debug: true,
    backend: {
      loadPath: `${initialProjectDirectory}/locales/{{lng}}/translation.json?cb=${new Date().getTime()}`,
    },
  })

export default i18n
