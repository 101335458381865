import { Button } from "primereact/button"

export const getEditButtons = (formAccessState, i18n, dialogState, toastRef, loading) => {
  const [formAccessStateValue, setFormAccessStateValue] = formAccessState

  const { t } = i18n

  switch (formAccessStateValue?.access) {
    case "readOnly":
      return (
        <Button
          {...(loading ? { loading: true } : {})}
          type="button"
          icon="pi pi-pencil"
          label={t("repeatedSentences.changeData")}
          onClick={() => setFormAccessStateValue((prevValue) => ({ ...prevValue, access: "normal" }))}
        />
      )

    case "disabled":
      return (
        <Button
          {...(loading ? { loading: true } : {})}
          type="button"
          icon="pi pi-pencil"
          label={t("repeatedSentences.changeData")}
          onClick={() => setFormAccessStateValue((prevValue) => ({ ...prevValue, access: "normal" }))}
        />
      )

    case "normal":
      return (
        <>
          <Button
            {...(loading ? { loading: true } : {})}
            className="margin_right"
            icon="pi pi-check"
            label={t("repeatedWords.accept")}
            onClick={() => setFormAccessStateValue((prevValue) => ({ ...prevValue, access: "normal" }))}
          />
          <Button
            {...(loading ? { disabled: true } : {})}
            type="button"
            icon="pi pi-times "
            label={t("repeatedWords.cancel")}
            onClick={() => setFormAccessStateValue((prevValue) => ({ ...prevValue, access: "disabled" }))}
          />
        </>
      )

    default: {
      console.error("Unknown access value.")
      return {}
    }
  }
}
