export const getFieldRulesObjByAccessState = (formAccessState, defaultRulesObj = {}) => {
  const [formAccessStateValue] = formAccessState

  switch (formAccessStateValue?.access) {
    case "readOnly":
      return { disabled: true }

    case "disabled":
      return { disabled: true }

    case "normal":
      return defaultRulesObj

    default: {
      console.error("Unknown access value.")
      return {}
    }
  }
}
