import { Fieldset } from "primereact/fieldset"
import { Divider } from "primereact/divider"
import { Controller } from "react-hook-form"
import { InputText } from "primereact/inputtext"
import { FieldError } from "../../../../../../../shared/FieldError"
import { getFieldRulesObjByAccessState } from "../../../getFieldRulesObjByAccessState"
import { getFieldAccessPropObjByAccessState } from "../../../getFieldAccessPropObjByAccessState"
import { useMemo } from "react"

export const PrivatePersonOrCompany = ({ formState, formAccessState, i18n }) => {
  const {
    formState: { errors },
    control,
    getValues,
  } = formState

  const { t } = i18n

  const activityTypeFieldValue = useMemo(() => getValues("privatePersonOrCompany.activityType"))

  console.log(activityTypeFieldValue, "activityTypeFieldValue")

  return (
    <Fieldset className="fieldSet" legend={`${t("repeatedSentences.privatePerson")} / ${t("repeatedWords.company")}`}>
      <section className="three_columns_grid_base">
        <div>
          <label className="block">{t("repeatedSentences.activityType")}</label>
          <h3 className="margin0 beUpColor">{activityTypeFieldValue}</h3>
        </div>
      </section>

      {activityTypeFieldValue === "Osoba Prywatna" ? (
        <>
          <Divider />

          <h3 className="beUpColor">{t("repeatedSentences.privatePerson")}</h3>
          <section className="three_columns_grid_base">
            <div>
              <label className="block">{`${t("repeatedWords.name")} *`}</label>
              <Controller
                name="privatePersonOrCompany.privatePerson.name"
                control={control}
                rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
                render={({ field }) => (
                  <InputText
                    {...getFieldAccessPropObjByAccessState(formAccessState)}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    maxLength={50}
                  />
                )}
              />
              <FieldError fieldErr={errors?.privatePersonOrCompany?.privatePerson?.name} />
            </div>

            <div>
              <label className="block">{`${t("repeatedWords.surname")} *`}</label>
              <Controller
                name="privatePersonOrCompany.privatePerson.surname"
                control={control}
                rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
                render={({ field }) => (
                  <InputText
                    {...getFieldAccessPropObjByAccessState(formAccessState)}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    maxLength={50}
                  />
                )}
              />
              <FieldError fieldErr={errors?.privatePersonOrCompany?.privatePerson?.surname} />
            </div>

            <div>
              <label className="block">{`${t("repeatedSentences.phoneNumber")} *`}</label>
              <Controller
                name="privatePersonOrCompany.privatePerson.phoneNumber"
                control={control}
                rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
                render={({ field }) => (
                  <InputText
                    {...getFieldAccessPropObjByAccessState(formAccessState)}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
              <FieldError fieldErr={errors?.privatePersonOrCompany?.privatePerson?.phoneNumber} />
            </div>

            <div>
              <label className="block">{`${t("repeatedWords.email")} *`}</label>
              <Controller
                name="privatePersonOrCompany.privatePerson.email"
                control={control}
                rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
                render={({ field }) => (
                  <InputText
                    {...getFieldAccessPropObjByAccessState(formAccessState)}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
              <FieldError fieldErr={errors?.privatePersonOrCompany?.privatePerson?.email} />
            </div>
          </section>
        </>
      ) : (
        <></>
      )}

      {activityTypeFieldValue === "Firma" ? (
        <>
          <Divider />
          <h3 className="beUpColor">{t("repeatedWords.company")}</h3>
          <section className="three_columns_grid_base">
            <div>
              <label className="block">{`${t("repeatedWords.nameOfObject")} *`}</label>
              <Controller
                name="privatePersonOrCompany.company.name"
                control={control}
                rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
                render={({ field }) => (
                  <InputText
                    {...getFieldAccessPropObjByAccessState(formAccessState)}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    maxLength={50}
                  />
                )}
              />
              <FieldError fieldErr={errors?.privatePersonOrCompany?.company?.name} />
            </div>

            <div>
              <label className="block">NIP *</label>
              <Controller
                name="privatePersonOrCompany.company.nip"
                control={control}
                rules={getFieldRulesObjByAccessState(formAccessState, { required: true })}
                render={({ field }) => (
                  <InputText
                    {...getFieldAccessPropObjByAccessState(formAccessState)}
                    value={field.value}
                    onChange={(e) => field.onChange(e.target.value)}
                    maxLength={50}
                  />
                )}
              />
              <FieldError fieldErr={errors?.privatePersonOrCompany?.company?.nip} />
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
    </Fieldset>
  )
}
